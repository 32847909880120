import React, {useContext, useEffect, useState} from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link} from "react-router-dom";

const Slider = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true)
    }, [])

    return (
        <section className="big-section cover-background background-position-center-bottom pt-0"
                 style={{backgroundImage: "url('/assets/images/banner.png')"}}
                >
            <div className="container">
                <div className="row align-items-center h-720px lg-h-550px md-h-550px xs-h-750px">
                    <div className="col-12 col-xl-5 col-md-6 sm-margin-40px-bottom">
                        <h5 className="alt-font font-weight-600 text-medium-slate-blue text-uppercase margin-5px-bottom">
                            مرکز شتابدهی تخصصی
                        </h5>
                        <h1 className="alt-font font-weight-700 text-uppercase text-gradient-magenta-orange-2 margin-25px-bottom letter-spacing-minus-1px">

                            اینترنت اشیا
                        </h1>
                        <p className="w-90 line-height-30px">
                            اکسلیوت (AccelIioT) شتاب‌دهنده تخصصی اینترنت اشیاء می‌باشد که از سال 1395 در هولدینگ بیتا با رویکرد توسعه داخلی هولدینگ فعالیت خود را آغاز نمود. تعداد رويدادهای برگزار شده توسط آكادمی اينترنت اشيا به عنوان مجموعه‌ای كه فعاليت شتابدهنده در آن انجام می‌شود تا کنون بيش از 300 رويداد بوده است. شتابدهنده اکسلیوت آماده جدب تیم در تمامی حوزه ‏های مرتبط با اینترنت اشیا و فناوری‏‌های وابسته
                        </p>
                        <Link to="/consulting"
                           className="btn btn-fancy btn-large btn-primary margin-30px-left  xs-margin-15px-bottom">
                           درخواست مشاوره
                        </Link>
                        <Link to="/about"
                           className="popup-youtube btn btn-link btn-extra-large text-medium-slate-blue xs-margin-30px-bottom">
                            فعالیت های ما
                        </Link>
                    </div>
                    <div className="col-12 col-xl-7 col-md-6 text-center text-lg-end">
                        <img src="/assets/images/vector-header.png" alt=""/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Slider;
