import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet"

const JoinUs = () => {
    const [alert, setAlert] = useState(0);
    const [res, setRes] = useState("");
    const joinUsSubmit = (e) => {
        // e.preventDefault()
        //     console.log(1)
        let firstName = document.getElementsByName('fistname')[0].value;
        let familyName = document.getElementsByName('family')[0].value;
        let emailAddress = document.getElementsByName('your-email')[0].value;
        let phoneNumber = document.getElementsByName('phone')[0].value;
        let companyName = document.getElementsByName('CompanyName')[0].value;
        let businessType = document.getElementsByName('BusinessType')[0].value;
        let address = document.getElementsByName('Address')[0].value;
        let streetAddress = document.getElementsByName('StreetAddress')[0].value;
        let streetAddressLine = document.getElementsByName('StreetAddressLine')[0].value;
        let country = document.getElementsByName('country')[0].value;
        let state = document.getElementsByName('state')[0].value;
        let zip = document.getElementsByName('zip')[0].value;
        let describe = document.getElementsByName('Describe')[0].value;
        let help = document.getElementsByName('Help')[0].value;


        if (firstName !== "" && emailAddress !== "" && familyName !== "" && phoneNumber !== "" && companyName !== "" &&
            country !== ""
            && describe !== "" && help !== "") {
            let formdata = new FormData();
            formdata.append("fist-name", firstName);
            formdata.append("family-name", familyName);
            formdata.append("email-address", emailAddress);
            formdata.append("phone-number", phoneNumber);
            formdata.append("company-name", companyName);
            formdata.append("business-type", businessType);
            formdata.append("address", address);
            formdata.append("street-address", streetAddress);
            formdata.append("street-address-line", streetAddressLine);
            formdata.append("country", country);
            formdata.append("state", state);
            formdata.append("zipcode", zip);
            formdata.append("describe", describe);
            formdata.append("Help", help);

            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            fetch("https://api.pionatec.com/wp-json/contact-form-7/v1/contact-forms/280/feedback", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setRes(result.message);
                    setAlert(1);
                    document.getElementById('contactForm').reset();
                })
                .catch(error => console.log('error', error));
        }
    }

    return (<div>
        <Helmet>
            <title>AcceLiot - Join Us</title>
        </Helmet>
        <section className="wow animate__fadeIn bg-light-gray padding-25px-tb page-title-small">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-xl-8 col-lg-6">
                        <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom text-center text-lg-start">Join
                            Us Form</h1>
                    </div>
                    <div
                        className="col-12 col-xl-4 col-lg-6 breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-10px-top">
                        <ul className="xs-text-center">
                            <li><Link to="/">Home</Link></li>
                            <li>Join Us</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className="pt-0 wow animate__fadeIn margin-100px-top">
            <div className="container">
                <div className="row">
                    <div
                        className="col-12 col-lg-7 col-md-6 padding-70px-right lg-padding-40px-right md-padding-15px-right mx-auto">
                        {(alert === 0) ? "" :
                            <div
                                className="col-12 margin-35px-bottom alert alert-success alert-dismissible fade in show"
                                role="alert">

                                <strong>Success!</strong> {res}
                            </div>
                        }
                        <form className="" id="contactForm">
                            <div className="row">
                                  <span
                                      className="alt-font text-large text-extra-dark-gray margin-40px-bottom d-inline-block font-weight-500">Business Owner</span>
                                <div className="col-md-6 margin-10px-bottom">
                                    <label className="margin-15px-bottom">First name <span
                                        className="text-radical-red">*</span></label>
                                    <input name="fistname" className="small-input" type="text" required/>
                                </div>
                                <div className="col-md-6 margin-10px-bottom">
                                    <label className="margin-15px-bottom">Last name <span
                                        className="text-radical-red">*</span></label>
                                    <input name="family" className="small-input" type="text" required/>
                                </div>
                                <div className="col-md-6 margin-10px-bottom">
                                    <label className="margin-15px-bottom">Email <span
                                        className="text-radical-red">*</span></label>
                                    <input name="your-email" className="small-input" type="text" required/>
                                </div>
                                <div className="col-md-6 margin-10px-bottom">
                                    <label className="margin-15px-bottom">Contact Number <span
                                        className="text-radical-red">*</span></label>
                                    <input name="phone" className="small-input" type="tel" required/>
                                </div>
                                <hr/>
                                <span
                                    className="alt-font text-large text-extra-dark-gray margin-40px-bottom d-inline-block font-weight-500">Business Details</span>
                                <div className="col-md-6 margin-10px-bottom">
                                    <label className="margin-15px-bottom">Company Name<span
                                        className="text-radical-red">*</span></label>
                                    <input name="CompanyName" className="small-input" type="text" required/>
                                </div>
                                <div className="col-md-6 margin-10px-bottom">
                                    <label className="margin-15px-bottom" htmlFor="country">Business Type</label>
                                    <select name="BusinessType" id="BusinessType" className="small-input bg-white">
                                        <option value="">Select</option>
                                        <option value="btob">B to B</option>
                                        <option value="ctoc">c to c</option>
                                        <option value="btoc">B to c</option>
                                    </select>
                                </div>
                                <div className="col-12 margin-15px-top">
                                    <label className="margin-15px-bottom">Address</label>
                                    <textarea name="Address" className="medium-input" rows="1" cols="1"></textarea>
                                </div>
                                <div className="col-12 margin-15px-top">
                                    <label className="margin-15px-bottom">Street Address</label>
                                    <textarea name="StreetAddress" className="medium-input" rows="1"
                                              cols="1"></textarea>
                                </div>
                                <div className="col-12 margin-15px-top">
                                    <label className="margin-15px-bottom">Street Address Line2</label>
                                    <textarea name="StreetAddressLine" className="medium-input" rows="1"
                                              cols="1"></textarea>
                                </div>
                                <div className="col-md-6 margin-10px-bottom">
                                    <label className="margin-15px-bottom" htmlFor="country">Country<span
                                        className="text-radical-red">*</span></label>
                                    <select name="country" id="country" className="small-input bg-white">
                                        <option>Select a country</option>
                                        <option value="Afganistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bonaire">Bonaire</option>
                                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                        <option value="Brunei">Brunei</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Canary Islands">Canary Islands</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Channel Islands">Channel Islands</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Island">Cocos Island</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cote DIvoire">Cote DIvoire</option>
                                        <option value="Croatia">Croatia</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Curaco">Curacao</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Ter">French Southern Ter</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Great Britain">Great Britain</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Hawaii">Hawaii</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="India">India</option>
                                        <option value="Iran">Iran</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Isle of Man">Isle of Man</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Korea North">Korea North</option>
                                        <option value="Korea Sout">Korea South</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon">Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libya">Libya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Midway Islands">Midway Islands</option>
                                        <option value="Moldova">Moldova</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Nambia">Nambia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherland Antilles">Netherland Antilles</option>
                                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                        <option value="Nevis">Nevis</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau Island">Palau Island</option>
                                        <option value="Palestine">Palestine</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Phillipines">Philippines</option>
                                        <option value="Pitcairn Island">Pitcairn Island</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                                        <option value="Republic of Serbia">Republic of Serbia</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russia</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="St Barthelemy">St Barthelemy</option>
                                        <option value="St Eustatius">St Eustatius</option>
                                        <option value="St Helena">St Helena</option>
                                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                        <option value="St Lucia">St Lucia</option>
                                        <option value="St Maarten">St Maarten</option>
                                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                        <option value="Saipan">Saipan</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="Samoa American">Samoa American</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra Leone">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Sri Lanka">Sri Lanka</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syria</option>
                                        <option value="Tahiti">Tahiti</option>
                                        <option value="Taiwan">Taiwan</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Erimates">United Arab Emirates</option>
                                        <option value="United States of America">United States of America</option>
                                        <option value="Uraguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Vatican City State">Vatican City State</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Vietnam</option>
                                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                        <option value="Wake Island">Wake Island</option>
                                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Zaire">Zaire</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                </div>
                                <div className="col-md-6 margin-10px-bottom">
                                    <label className="margin-15px-bottom">State/Province</label>
                                    <input name="state" className="small-input" type="text" required/>
                                </div>
                                <div className="col-md-12 margin-10px-bottom">
                                    <label className="margin-15px-bottom">Postal/Zip code</label>
                                    <input name="zip" className="small-input" type="number" required/>
                                </div>
                                <div className="col-12 margin-15px-top">
                                    <label className="margin-15px-bottom">Describe your Business<span
                                        className="text-radical-red">*</span></label>
                                    <textarea name="Describe" className="medium-input" rows="1" cols="1"></textarea>
                                </div>
                                <div className="col-12 margin-15px-top">
                                    <label className="margin-15px-bottom">How can we Help you<span
                                        className="text-radical-red">*</span></label>
                                    <textarea name="Help" className="medium-input" rows="1" cols="1"></textarea>
                                </div>
                                <div className="col-12 margin-15px-top">
                                    <button
                                        className="btn btn-large no-border btn-primary submit"
                                        type="button" onClick={joinUsSubmit}>Send
                                    </button>
                                </div>

                                <input type="hidden" name="redirect" value=""/>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>);
};

export default JoinUs;