import {createContext, useState} from 'react';
export const AudienceDataContext = createContext(undefined);

export const AudienceDataProvider = (props) => {
    const [audienceItems, setAudienceItems] = useState([])
    return (
        <AudienceDataContext.Provider value={{audienceItems, setAudienceItems}}>
            {props.children}
        </AudienceDataContext.Provider>
    );
};