import {createContext, useState} from 'react';
export const BlogTagsDataContext = createContext(undefined);

export const BlogTagsDataProvider = (props) => {
    const [blogTagsItems, setBlogTagsItems] = useState([])
    return (
        <BlogTagsDataContext.Provider value={{blogTagsItems, setBlogTagsItems}}>
            {props.children}
        </BlogTagsDataContext.Provider>
    );
};