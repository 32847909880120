import React from 'react';

const Possibilities = () => {
    return (
        <div>
            <section className="overlap-height md-no-padding-top xs-padding-40px-top">
                <div className="container">
                    <div className="row align-items-center justify-content-center overlap-gap-section">
                        <div className="col-md-12 text-start">
                            <h6 className="alt-font text-extra-dark-gray font-weight-500">ما برای تیم‌ها چه امکاناتی فراهم می‌کنیم ؟</h6>
                        </div>
                        <div className="col-12 col-lg-5 offset-lg-1 col-md-10 ps-lg-0 order-2 order-md-1">
                            <ul className="list-style-06">
                                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn"
                                    data-wow-delay="0.2s">
                                    <div>
                                        <span
                                               className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-left margin-5px-top d-flex flex-column">
                                                <i className="feather icon-feather-check"></i>
                                            </span>
                                    </div>
                                    <div>
                                        <span
                                            className="text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">بهره‌مندی از خدمات آزمایشگاهی</span>
                                        <p className="w-80 lg-w-90 xs-w-100">به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود.</p>
                                    </div>
                                </li>
                                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn"
                                    data-wow-delay="0.4s">
                                    <div><span
                                        className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-left margin-5px-top d-flex flex-column"><i
                                        className="feather icon-feather-check"></i></span></div>
                                    <div><span
                                        className="text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">نمونه سازی سه‌بعدی با پیاده سازی سه‌بعدی</span>
                                        <p className="w-80 lg-w-90 xs-w-100">به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود.</p>
                                    </div>
                                </li>
                                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn"
                                    data-wow-delay="0.4s">
                                    <div><span
                                        className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-left margin-5px-top d-flex flex-column"><i
                                        className="feather icon-feather-check"></i></span></div>
                                    <div><span
                                        className="text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">
تجاری سازی بارویکرد صادراتی</span>
                                        <p className="w-80 lg-w-90 xs-w-100">به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="col-12 col-lg-6 col-md-10 text-center text-lg-end md-margin-30px-bottom wow animate__fadeIn order-1 order-md-2"
                            data-wow-delay="0.2s">
                            <img src="/assets/images/vector-fiture.png" alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Possibilities;