import {createContext, useState} from 'react';
export const BlogDataContext = createContext(undefined);

export const BlogDataProvider = (props) => {
    const [blogItems, setBlogItems] = useState([])
    return (
        <BlogDataContext.Provider value={{blogItems, setBlogItems}}>
            {props.children}
        </BlogDataContext.Provider>
    );
};