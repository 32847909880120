import React, {useContext, useEffect} from 'react';
import {Link} from "react-router-dom";
import {useState} from "react";
import ServiceCard from "../../components/Services/ServiceCard";
import ServiceCardSkeleton from "../../components/Services/ServiceCardSkeleton";
import {ServiceDataContext} from "../../context/ServiceDataContext";
import {apiGet} from "../../components/Tools/apiConfig";
import Possibilities from "../../components/Tools/Possibilities";
import {Helmet} from "react-helmet";

const ServicesList = () => {
    const {serviceItems, setServiceItems} = useContext(ServiceDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const getServicesItems = async () => {

        const baseURL = '/wp-json/wp/v2/services?_fields[]=title&_fields[]=content&_fields[]=featured_media&_fields[]=slug&_fields[]=excerpt&_fields[]=better_featured_image&_fields[]=date';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setServiceItems(response.data);
                setIsLoaded(true);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (serviceItems.length > 0) {
            setIsLoaded(true)
        } else {
            getServicesItems();
        }
    }, [serviceItems])

    return (
        <>
            <Helmet>
                <title>AcceLiot - Services</title>
            </Helmet>
            <section
                className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                            <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                خدمات ما
                            </h1>
                            {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                        </div>
                        <div
                            className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                            <ul>
                                <li><Link to="/">صفحه اصلی</Link></li>
                                <li className="text-primary">خدمات</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>


            <section className="big-section wow animate__fadeIn">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center margin-seven-bottom">
                            <h6 className="alt-font text-extra-dark-gray font-weight-500">خدمات عمومی مرکز شتابدهی</h6>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-sm-1 justify-content-center">

                        {(isLoaded === true) ?

                            serviceItems.map((item, i) =>
                                <ServiceCard key={i} elements={item}/>
                            ) :
                            Array.apply(0, Array(6)).map((item, i) =>
                                <ServiceCardSkeleton key={i}/>
                            )
                        }


                    </div>
                </div>
            </section>


            <Possibilities/>
        </>
    );
};

export default ServicesList;