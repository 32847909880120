import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {apiGet} from "../Tools/apiConfig";
import {BlogCategoryDataContext} from "../../context/BlogCategoryDataContext";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BlogCategory = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const {blogCategoryItems, setBlogCategoryItems} = useContext(BlogCategoryDataContext);
    const getBlogCategoryItems = async () => {

        const baseURL = '/wp-json/wp/v2/categories?_fields[]=name&_fields[]=slug&_fields[]=count&_fields[]=id';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setBlogCategoryItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (blogCategoryItems.length > 0) {
            setIsLoaded(true)
        } else {
            getBlogCategoryItems();
        }
    }, [blogCategoryItems])

    return (
        <ul className="list-style-07 list-unstyled">
            {isLoaded ? blogCategoryItems.map((item, index) => {
                return (
                    <li key={index}>
                        <span className="item-qty">{item.count}</span>
                        <Link to={`/blog/category/${item.slug}`}>{item.name}</Link>
                    </li>
                )
            }) : Array(3).fill(0).map((item, index) =>
                <Skeleton key={index}/>)}
        </ul>
    );
};

export default BlogCategory;