import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet"
import {apiPost} from "../../components/Tools/apiConfig";

const Contact = () => {
    const [alert, setAlert] = useState(0);
    const [res, setRes] = useState("");
    const contactUsStbmit = () => {
        let name = document.getElementsByName('name')[0].value;
        let email = document.getElementsByName('email')[0].value;
        let phone = document.getElementsByName('phone')[0].value;
        let text = document.getElementsByName('message')[0].value;
        if (name !== "" && email !== "" && phone !== "" && text !== "") {
            let formdata = new FormData();
            formdata.append("your-name", name);
            formdata.append("your-email", email);
            formdata.append("your-phone", phone);
            formdata.append("your-message", text);


            apiPost("/wp-json/contact-form-7/v1/contact-forms/5/feedback", formdata)
                .then(response => {
                    if (response.data.status === 'mail_sent') {
                        setAlert(1);
                        setRes({
                            status: 'success',
                            message: "پیام شما با موفقیت ارسال شد."
                        });
                        document.getElementById('startup-form').reset();
                    } else {
                        setAlert(1);
                        setRes({
                            status: 'error',
                            message: "متاسفانه پیام شما ارسال نشد."
                        });
                    }
                })
                .catch(error => {
                    setAlert(1);
                    setRes({
                        status: 'error',
                        message: "اتصال اینترنت خود را بررسی کنید."
                    });
                });
        }

    }
    return (
        <>
            <Helmet>
                <title>AcceLiot - Contact Us</title>
            </Helmet>
            <section className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                            <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                تماس با ما
                            </h1>
                            {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                        </div>
                        <div
                            className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                            <ul>
                                <li><Link to="/">صفحه اصلی</Link></li>
                                <li className="text-primary">تماس با ما</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wow animate__fadeIn">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row justify-content-center">
                                <div
                                    className="col-12 col-xl-6 col-lg-7 text-center margin-4-half-rem-bottom md-margin-3-rem-bottom">
                            <span
                                className="alt-font letter-spacing-minus-1-half text-extra-medium d-block margin-5px-bottom">
                                فرم را پر کنید و به زودی با شما تماس خواهیم گرفت!
                            </span>
                                    <h4 className="alt-font font-weight-600 text-extra-dark-gray">
                                        ما چگونه می توانیم به شما کمک کنیم؟
                                    </h4>
                                </div>
                                <div className="col-12">
                                    {(alert === 0) ? "" :
                                        (res.status === 'success') ?
                                            <div className="alert alert-success" role="alert">
                                                {res.message}
                                            </div>
                                            :
                                            <div className="alert alert-danger" role="alert">
                                                {res.message}
                                            </div>
                                    }
                                    <form id="contactForm">
                                        <div className="row row-cols-1 row-cols-md-2">
                                            <div className="col margin-4-rem-bottom sm-margin-25px-bottom">
                                                <input className="medium-input bg-white margin-25px-bottom required"
                                                       type="text"
                                                       name="name" placeholder="اسم شما"/>
                                                <input className="medium-input bg-white margin-25px-bottom required"
                                                       type="email"
                                                       name="email" placeholder="آدرس ایمیل شما"/>
                                                <input className="medium-input bg-white mb-0" type="tel"
                                                       name="phone"
                                                       placeholder="تلفن شما"/>
                                            </div>
                                            <div className="col margin-4-rem-bottom sm-margin-10px-bottom">
                                        <textarea className="medium-textarea h-200px bg-white required" name="message"
                                                  placeholder="پیام شما" required/>
                                            </div>
                                            <div className="col text-start sm-margin-30px-bottom">
                                                <div className={"d-none d-md-inline"}>
                                                    <i
                                                        className="feather icon-feather-mail icon-very-small margin-10px-right"/><a
                                                    href="#">info@acceliot.com</a>
                                                </div>
                                            </div>
                                            <div className="col text-center text-md-end">
                                                <input type="hidden" name="redirect" value=""/>
                                                <button onClick={contactUsStbmit}
                                                        className="btn btn-medium btn-primary mb-0 submit"
                                                        type="button">ارسال پیام
                                                </button>
                                            </div>
                                            <div className={"d-md-none mt-5 text-center"}>
                                                <i
                                                    className="feather icon-feather-mail icon-very-small margin-10px-right"/><a
                                                href="#">info@pionatec.com</a>
                                            </div>
                                        </div>
                                        <div className="form-results d-none"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;