import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard} from "swiper";

const PartnersLogo = (props) => {
    const el = props.elements;
    return (
        <Swiper
            modules={[Navigation, Autoplay, Keyboard]}
            className={'swiper-container slider-zoom-slide slider-zoom-slide-dark black-move p-0'}
            spaceBetween={0}
            slidesPerView={4}
            loop={true}
            centeredSlides={true}
            initialSlide={2}
            speed={10000}
            autoplay={{
                delay: 1,
                disableOnInteraction: false
            }}
            breakpoints={{
                992: {
                    slidesPerView: 7
                },
                767: {
                    slidesPerView: 4,
                }
            }}
            keyboard={{enabled: true, onlyInViewport: true}}
            navigation={{
                prevEl: ".swiper-button-previous-nav",
                nextEl: ".swiper-button-next-nav",
            }}
        >
            {
                el.map((item, i) =>
                    <SwiperSlide key={i}>
                        <div className="client-logo">
                            <img className="partner-img"
                                 src={item.better_featured_image !== null ? item.better_featured_image.source_url : `http://placehold.it/300x200&text=${item.title.rendered}`}
                                alt={item.title.rendered}/>
                        </div>
                    </SwiperSlide>
                )
            }
        </Swiper>
    );
};

export default PartnersLogo;