import {useState} from 'react';
import {apiPost} from "./apiConfig";

const NewsLetter = () => {
    const [abs, setAbs] = useState(0)
    const NewsLetterSubmit = (e) => {
        e.preventDefault();
        let mailId = document.getElementsByName('emailId')[0].value;
        if (mailId !== "") {
            let formdata = new FormData();
            formdata.append("news-email", mailId);


            apiPost("/wp-json/contact-form-7/v1/contact-forms/78/feedback", formdata)
                .then(response => {
                    if (response.data.status === 'mail_sent') {
                        setAbs(1)
                    } else {
                        alert("اتصال خود ره اینترنت را بررسی کنید")
                    }
                })
                .catch(error => {
                    alert("اتصال خود ره اینترنت را بررسی کنید")
                });
        }
    }
    if (abs === 0)
        return (

            <form id="news-letter">
                <div className="newsletter-style-03 position-relative">
                    <input className="small-input border-color-medium-gray rounded m-0 required"
                           name="emailId" placeholder="آدرس ایمیل" type="email"/>
                    <input type="hidden" name="redirect" value=""/>
                    <button
                        className="btn no-border btn-primary rounded-start submit"
                        type="button" onClick={NewsLetterSubmit}>
                        <i className="feather icon-feather-mail icon-extra-small m-0"/>
                    </button>

                </div>
            </form>
        );
    else
        return (
            <div className="form-results rounded">
             با موفقیت نام شما ثبت شد
            </div>
        )

};

export default NewsLetter;