import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import {EventDataContext} from "../../context/EventDataContext";
import {apiGet} from "../../components/Tools/apiConfig";

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard} from "swiper";
import {Gallery, Item} from "react-photoswipe-gallery";

const EventDetail = () => {
    const {eventItems, setEventItems} = useContext(EventDataContext);
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [date, setDate] = useState('');
    const [gallery, setGallery] = useState([]);
    // const [comments, setComments] = useState([]);
    const [isLoaded, setLoading] = useState(false);
    const {slug} = useParams()


    const getData = async () => {
        if (eventItems.length > 0) {
            let item = await eventItems.find(element => decodeURIComponent(element.slug) === slug);
            if (item) {
                setId(item.id);
                setTitle(item.title.rendered);
                setContent(item.content.rendered);
                setDate(item.date)
                setImage(item.better_featured_image !== null ? item.better_featured_image.source_url : `http://placehold.it/900x600&text=${item.title.rendered}`);
                setLoading(true);
                setGallery((item.gallery) ? item.gallery : [])
            }
        }
    };

    const getEventItems = async () => {

        const baseURL = '/wp-json/wp/v2/event?_fields[]=title&_fields[]=content&_fields[]=featured_media&_fields[]=slug&_fields[]=excerpt&_fields[]=better_featured_image&_fields[]=date&_fields[]=date&_fields[]=date&_fields[]=date&_fields[]=gallery';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setEventItems(response.data);
                setLoading(true);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (eventItems.length > 0) {
            getData();
        } else {
            getEventItems();
        }
    }, [eventItems, slug])


    return (
        <>
            <Helmet>
                <title>AcceLiot - Events - {title}</title>
                <meta property="og:title" content={title}/>
                {/*<meta property="og:image"*/}
                {/*      content={states.items.seo.og_image[0].url}/>*/}
                {/*<meta name="robots" content="noindex" />*/}
            </Helmet>
            <section className="wow animate__fadeIn">
                <div className="container">
                    <div className="row justify-content-center">
                        <div
                            className="col-12 col-xl-5 col-lg-6 col-sm-8 text-center margin-5-rem-bottom lg-margin-4-rem-bottom">
                            <span
                                className="alt-font text-orange font-weight-500 d-block margin-15px-bottom sm-margin-5px-bottom">
                                     {
                                         (isLoaded) ?

                                             new Date(date).toLocaleDateString('fa-IR', {
                                                 year: 'numeric',
                                                 month: 'long',
                                                 day: 'numeric'
                                             }) :
                                             <Skeleton width={'100px'}/>
                                     }
                            </span>
                            <h5 className="alt-font text-extra-dark-gray font-weight-500">
                                {title}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="container-fluid padding-nine-lr xl-padding-three-lr sm-padding-15px-lr">
                    <Swiper
                        modules={[Navigation, Keyboard]}
                        className={'swiper-container slider-zoom-slide slider-zoom-slide-dark black-move p-0 margin-5-rem-bottom lg-margin-4-rem-bottom'}
                        spaceBetween={10}
                        slidesPerView={4}
                        loop={true}
                        centeredSlides={true}
                        initialSlide={2}
                        preloadImages={true}
                        updateOnImagesReady={true}
                        speed={5000}
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            992: {
                                slidesPerView: 4
                            },
                            767: {
                                slidesPerView: 4,
                            }
                        }}
                        onMoveShouldSetResponderCapture={() => true}
                        keyboard={{enabled: true}}
                        navigation={{
                            prevEl: ".swiper-button-previous-nav",
                            nextEl: ".swiper-button-next-nav",
                        }}
                    >
                        <Gallery>
                            {
                                gallery.map((item, i) =>
                                    <SwiperSlide key={i}>
                                        <div className="grid-item wow animate__fadeIn" data-wow-delay="0.2s">
                                            <Item
                                                original={item.guid}
                                                thumbnail={item.guid}
                                                width="1024"
                                                height="768"
                                            >
                                                {({ref, open}) => (
                                                    <img ref={ref} onClick={open}
                                                         src={item.guid}
                                                         alt=""/>
                                                )}
                                            </Item>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        </Gallery>
                    </Swiper>
                </div>
                <div className="container padding-nine-lr xl-padding-three-lr sm-padding-15px-lr">
                    {
                        (isLoaded) ?
                            <div dangerouslySetInnerHTML={{__html: content}}/> :
                            <Skeleton count={10}/>
                    }
                </div>

            </section>


            {/*<Gallery>*/
            }
            {/*    <Item*/
            }
            {/*        original={'http://placehold.it/900x600&text=Image 1'}*/
            }
            {/*        thumbnail={'http://placehold.it/900x600&text=Image 2'}*/
            }
            {/*        width="1024"*/
            }
            {/*        height="768"*/
            }
            {/*    >*/
            }
            {/*        {({ref, open}) => (*/
            }
            {/*            <img ref={ref} onClick={open} src={'http://placehold.it/900x600&text=Image 1'} alt=""/>*/
            }
            {/*        )}*/
            }
            {/*    </Item>*/
            }
            {/*</Gallery>*/
            }
        </>
    )
        ;
};

export default EventDetail;