import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {apiGet} from "../Tools/apiConfig";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {BlogTagsDataContext} from "../../context/BlogTagsDataContext";

const BlogTags = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const {blogTagsItems, setBlogTagsItems} = useContext(BlogTagsDataContext);
    const getBlogTagsItems = async () => {
        const baseURL = '/wp-json/wp/v2/tags?_fields[]=name&_fields[]=slug&_fields[]=count&_fields[]=id';
        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setBlogTagsItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (blogTagsItems.length > 0) {
            setIsLoaded(true)
        } else {
            getBlogTagsItems();
        }
    }, [blogTagsItems])

    return (
        <ul className="list-style-07 list-unstyled">
            {isLoaded ? blogTagsItems.map((item, index) => {
                    return (
                        <Link to={`/blog/tag/${item.slug}`} key={index}>{item.name}</Link>
                    )
                }
            ) : Array(3).fill(0).map((item, index) =>
                <Skeleton key={index}/>)}
        < /ul>
    );
};

export default BlogTags;