import {createContext, useState} from 'react';
export const RoadMapDataContext = createContext(undefined);

export const RoadMapDataProvider = (props) => {
    const [roadMapItems, setRoadMapItems] = useState([])
    return (
        <RoadMapDataContext.Provider value={{roadMapItems, setRoadMapItems}}>
            {props.children}
        </RoadMapDataContext.Provider>
    );
};