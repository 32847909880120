import React, {useContext, useEffect, useState} from 'react';
import {apiGet} from "../Tools/apiConfig";
import EventCard from "../Event/EventCard";
import {EventDataContext} from "../../context/EventDataContext";
import EventCardSkeleton from "../Event/EventCardSkeleton";

const Event = () => {
    const {eventItems, setEventItems} = useContext(EventDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const getEventItems = async () => {

        const baseURL = '/wp-json/wp/v2/event?_fields[]=title&_fields[]=content&_fields[]=featured_media&_fields[]=slug&_fields[]=excerpt&_fields[]=better_featured_image&_fields[]=date&_fields[]=date&_fields[]=date&_fields[]=date&_fields[]=gallery';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setEventItems(response.data);
                setIsLoaded(true);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (eventItems.length > 0) {
            setIsLoaded(true)
        } else {
            getEventItems();
        }
    }, [eventItems])


    return (
        <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div
                        className="col-12 col-xl-6 col-lg-7 col-sm-8 text-center margin-4-rem-bottom wow animate__fadeIn">
                        <h5 className="alt-font font-weight-500 text-extra-dark-gray d-inline-block letter-spacing-minus-1-half">
                            رویدادهای ما
                        </h5>
                    </div>
                </div>
                <div className="row">

                    {
                        (isLoaded === true) ?
                            eventItems.slice(0, 3).map((item, i) =>
                                <div key={i} className="col-lg-4 col-md-6 mb30">
                                    <EventCard elements={item} index={i}/>
                                </div>
                            ) :
                            Array.apply(0, Array(3)).map((item, i) =>
                                <div key={i} className="col-lg-4 col-md-6 mb30">
                                    <EventCardSkeleton elements={item}/>
                                </div>
                            )
                    }

                </div>
            </div>
        </section>
    );
};

export default Event;