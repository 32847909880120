import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {FaqDataContext} from "../../context/FaqDataContext";
import {apiGet} from "../../components/Tools/apiConfig";
import {Helmet} from "react-helmet";

const FaqList = () => {
    const {faqItems, setFaqItems} = useContext(FaqDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const getFaqItems = async () => {

        const baseURL = '/wp-json/wp/v2/faq?_fields[]=title&_fields[]=content&_fields[]=id';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setFaqItems(response.data);
                setIsLoaded(true);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (faqItems.length > 0) {
            setIsLoaded(true)
        } else {
            getFaqItems();
        }
    }, [faqItems])

    return (
        <>
            <Helmet>
                <title>AcceLiot - FAQ</title>
            </Helmet>
            <section className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                            <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                سوالات متداول
                            </h1>
                            {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                        </div>
                        <div
                            className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                            <ul>
                                <li><Link to="/">صفحه اصلی</Link></li>
                                <li className="text-primary">سوالات متداول</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light-gray pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center margin-7-rem-bottom">
                            <span className="d-block alt-font margin-5px-bottom">اینترنت اشیا</span>
                            <h5 className="alt-font text-extra-dark-gray font-weight-600 mb-0">سوالات متداول برای شرکت در شتابدهی</h5>
                        </div>
                        <div className="col-12 wow animate__fadeIn">
                            <div className="panel-group accordion-event accordion-style-03" id="accordion3"
                                 data-active-icon="icon-feather-chevron-down" data-inactive-icon="icon-feather-chevron-left">                                {
                                    faqItems.map((item, index) => {
                                        return (
                                            <div key={index}
                                                 className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <div className="panel-heading active-accordion">
                                                    <a className="accordion-toggle collapsed" data-bs-toggle="collapse"
                                                       data-bs-parent="#accordion3" href={'#collapse' + index}
                                                       aria-expanded="false">
                                                        <div className="panel-title">
                                                <span
                                                    className="alt-font text-extra-dark-gray d-inline-block font-weight-500">{item.title.rendered}</span>
                                                            <i className="indicator feather icon-feather-chevron-down text-fast-blue icon-extra-small"/>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id={'collapse' + index}
                                                     className={"panel-collapse collapse"}
                                                     data-bs-parent="#accordion3">
                                                    <div className="panel-body"
                                                         dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <span className="d-block alt-font margin-5px-bottom text-center">
                            آیا سوالات دیگری دارید؟
                        </span>
                        <Link to="/contact"
                              className="btn btn-fancy btn-large btn-primary margin-30px-left xs-margin-15px-top mx-auto">
                            تماس با ما
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqList;