import {createContext, useState} from 'react';
export const EventDataContext = createContext(undefined);

export const EventDataProvider = (props) => {
    const [eventItems, setEventItems] = useState([])
    return (
        <EventDataContext.Provider value={{eventItems, setEventItems}}>
            {props.children}
        </EventDataContext.Provider>
    );
};