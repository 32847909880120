import React, {useState} from 'react';
import {apiPost} from "../Tools/apiConfig";

const StartupForm = () => {
    const [alert, setAlert] = useState(0);
    const [res, setRes] = useState({
        status: '',
        message: ''
    });
    const submitStartupForm = (e) => {
        let firstName = document.getElementsByName('firstName')[0].value;
        let lastName = document.getElementsByName('lastName')[0].value;
        let email = document.getElementsByName('email')[0].value;
        let phone = document.getElementsByName('phone')[0].value;
        let activity = document.getElementsByName('activity')[0].value;
        let teamCount = document.getElementsByName('teamCount')[0].value;
        let website = document.getElementsByName('website')[0].value;
        let address = document.getElementsByName('address')[0].value;
        let education = document.getElementsByName('education')[0].value;
        let startupStatus = document.getElementsByName('startupStatus')[0].value;
        let activityLong = document.getElementsByName('activityLong')[0].value;
        let message = document.getElementsByName('message')[0].value;

        let formdata = new FormData();
        formdata.append("your-name", firstName);
        formdata.append("your-last-name", lastName);
        formdata.append("your-email", email);
        formdata.append("your-phone", phone);
        formdata.append("your-activity", activity);
        formdata.append("your-team-count", teamCount);
        formdata.append("your-website", website);
        formdata.append("your-address", address);
        formdata.append("your-education", education);
        formdata.append("your-startup-status", startupStatus);
        formdata.append("your-activity-long", activityLong);
        formdata.append("your-message", message);

            console.log(formdata)

        apiPost("/wp-json/contact-form-7/v1/contact-forms/77/feedback", formdata)
            .then(response => {
                if (response.data.status === 'mail_sent') {
                    setAlert(1);
                    setRes({
                        status: 'success',
                        message: "پیام شما با موفقیت ارسال شد."
                    });
                    document.getElementById('startup-form').reset();
                } else {
                    setAlert(1);
                    setRes({
                        status: 'error',
                        message: "متاسفانه پیام شما ارسال نشد."
                    });
                }
            })
            .catch(error => {
                setAlert(1);
                setRes({
                    status: 'error',
                    message: "اتصال اینترنت خود را بررسی کنید."
                });
            });
    };


    return (
        <form id="startup-form" onSubmit={submitStartupForm} onSubmitCapture={submitStartupForm}>
            {(alert === 0) ? "" :
                (res.status === 'success') ?
                    <div className="alert alert-success" role="alert">
                        {res.message}
                    </div>
                    :
                    <div className="alert alert-danger" role="alert">
                        {res.message}
                    </div>
            }
            <div className="row">
                <div className="col-md-6">
                    <input className="large-input margin-25px-bottom border-radius-0px required" type="text"
                           name="firstName" placeholder="نام"/>
                </div>
                <div className="col-md-6">
                    <input className="large-input margin-25px-bottom border-radius-0px required" type="text"
                           name="lastName" placeholder="نام خانوادگی"/>
                </div>
                <div className="col-md-6">
                    <input className="large-input margin-25px-bottom border-radius-0px required" type="email"
                           name="email" placeholder="ایمیل"/>
                </div>
                <div className="col-md-6">
                    <input className="large-input margin-25px-bottom border-radius-0px required" type="phone"
                           name="phone" placeholder="شماره همراه"/>
                </div>
                <div className="col-md-4">
                    <input className="large-input margin-25px-bottom border-radius-0px required" type="text"
                           name="activity" placeholder="حوزه فعالیت تیم شما"/>
                </div>
                <div className="col-md-4">
                    <input className="large-input margin-25px-bottom border-radius-0px required" type="number"
                           name="teamCount" placeholder="تعداد اعضای تیم شما"/>
                </div>
                <div className="col-md-4">
                    <input className="large-input margin-25px-bottom border-radius-0px required" type="text"
                           name="website" placeholder="وبسایت شما"/>
                </div>
                <div className="col-md-6">
                    <input className="large-input margin-25px-bottom border-radius-0px required" type="text"
                           name="address" placeholder="محل سکونت"/>
                </div>
                <div className="col-md-6">
                    <select name="education" className="large-input margin-25px-bottom border-radius-0px required">
                        <option value="" selected={true} disabled={true}>ضعیت تحصیل</option>
                        <option value="دانشجو">دانشجو</option>
                        <option value="فارغ التحصیل">فارغ التحصیل</option>
                    </select>
                </div>
                <div className="col-md-6">
                    <select name="startupStatus" className="large-input margin-25px-bottom border-radius-0px required">
                        <option value="" selected={true} disabled={true}>
                            استارت اپ شما در چه مرحله ای قرار دارد؟
                        </option>
                        <option value="درحال توسعه هستیم و هنوز به محصول اولیه نرسیدیم">درحال توسعه هستیم و هنوز به
                            محصول اولیه نرسیدیم
                        </option>
                        <option value="نمونه اولیه ساخته شده">نمونه اولیه ساخته شده</option>
                        <option value="محصول را رسما انتشار دادیم و نمونه اولیه داریم">محصول را رسما انتشار دادیم و
                            نمونه اولیه داریم
                        </option>
                        <option value="محصول را لانچ کردیم وبه نقطه سربه سر رسیدیم">محصول را لانچ کردیم وبه نقطه سربه سر
                            رسیدیم
                        </option>
                    </select>
                </div>

                <div className="col-md-6">
                    <select name="activityLong" className="large-input margin-25px-bottom border-radius-0px required">
                        <option value="" selected={true} disabled={true}>
                            چه مدت است بر روی استارت اپ خود فعالیت دارید؟
                        </option>
                        <option value="1-2سال">1-2سال</option>
                        <option value="3-4سال">3-4سال</option>
                        <option value="5سال بیشتر">5سال بیشتر</option>
                        <option value="کمتر از 1سال">کمتر از 1سال</option>
                    </select>
                </div>
                <div className="col-md-12">
                    <textarea name="message" id="" rows="10" placeholder="استارت اپ خود را در یک خط تعریف کنید"/>
                </div>
                <div className="col-md-12">
                    <div className="col text-center text-md-end">
                        <button className="btn btn-medium btn-primary mb-0 submit" type="button"
                                onClick={submitStartupForm}>ارسال درخواست
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default StartupForm;