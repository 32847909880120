import React from 'react';
import {Link} from "react-router-dom";

const Banner = () => {
    return (

        // <section className="cover-background wow animate__fadeIn margin-3-rem-bottom"
        <section className="parallax padding-100px-tb md-padding-75px-tb sm-padding-50px-tb"
                 style={{
                     background: "linear-gradient(to top, rgb(47, 9, 89), rgb(69 80 230), rgb(92, 35, 89)) fixed, url('/assets/images/banner-mainpage.jpg')",
                     backgroundAttachment: "fixed",
                     backgroundBlendMode: "multiply"
                 }}>
            <div className="opacity-medium bg-medium-slate-blue"></div>
            <div className="container">
                <div className="row">


                        <div
                            className="col-12 col-xl-7 col-md-8 col-sm-10 position-relative text-center text-md-start sm-margin-30px-bottom">
                            <h4 className="alt-font font-weight-600 text-white mb-5">
                               همین حالا به شتابدهنده اکسلیوت بپیوندید
                            </h4>
                            <span className="text-white alt-font text-uppercase">هر کجای مسیر راه‌اندازی کسب و کار هستید، از ایده به محصول اولیه رسیده تا یک شرکت بالغ، به ما بپیوندید. اکسلیوت با فراهم کردن فضای کار، جذب سرمایه‌گذار، مشاوره و آموزش میزبان تیم ها و شرکای جدید و سرمایه گزاران عزیز است</span>
                        </div>
                        <div className="col-12 col-xl-5 col-md-4 position-relative text-center text-md-end align-self-center">
                            <Link to="/consultingl" className="btn btn-large btn-white">درخواست مشاوره</Link>
                        </div>



                </div>
            </div>
        </section>
    );
};

export default Banner;