import React, {useContext, useEffect, useState} from "react";
import {RoadMapDataContext} from "../../context/RoadMapDataContext";
import {apiGet} from "../Tools/apiConfig";

const Roadmap = () => {
    const {roadMapItems, setRoadMapItems} = useContext(RoadMapDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const getRoadMapItems = async () => {

        const baseURL = '/wp-json/wp/v2/roadmap?_fields[]=title&_fields[]=content&_fields[]=featured_media&_fields[]=slug&_fields[]=excerpt&_fields[]=better_featured_image';
        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setRoadMapItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }
    useEffect(() => {
        if (roadMapItems.length > 0) {
            setIsLoaded(true)
        } else {
            getRoadMapItems();
        }
    }, [roadMapItems])

    const chooseToShowLine = (e) => {
        if (e === 1 || e === 2 || e === 4) {
            return true
        }
    }

    return (
        <div>
            <section className="big-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center margin-seven-bottom">
                            <h5 className="alt-font text-extra-dark-gray"> نقشه راه شتابدهی<span/>
                            </h5>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {
                            roadMapItems.map((item, i) =>

                                <div key={i} className="col-12 col-md-4 col-sm-8 text-center process-step-style-04 sm-margin-6-rem-bottom">
                                    <div className="process-step-item">
                                        <span className="process-step-item-bfr bg-white"/>
                                        <div className="process-step-item-box">
                                            {
                                                (chooseToShowLine(i)) ?
                                                    <span
                                                        className="process-step-item-box-bfr bg-white opacity-2 d-none d-md-block"/> :
                                                    ''
                                            }

                                            <div className="process-step-icon">
                                                <span className="process-step-icon-bfr bg-bluei"/>
                                                <span
                                                    className="process-step-number text-slate-blue alt-font font-weight-500"><span
                                                    className="process-step-number-bfr bg-bluei"/>{i+1}</span>
                                            </div>
                                            <div className="process-content last-paragraph-no-margin">
                                        <span
                                            className="alt-font d-block font-weight-800  margin-10px-bottom">{item.title.rendered}</span>
                                                <div dangerouslySetInnerHTML={{__html:item.content.rendered}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Roadmap;