import React, {useContext, useEffect, useState} from "react";
import {ServiceDataContext} from "../../context/ServiceDataContext";
import {apiGet} from "../Tools/apiConfig";

const Services = () => {
    const {serviceItems, setServiceItems} = useContext(ServiceDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const getServiceItems = async () => {

        const baseURL = '/wp-json/wp/v2/services';
        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setServiceItems(response.data);

            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (serviceItems.length > 0) {
            setIsLoaded(true)
        } else {
            getServiceItems();
        }
    }, [serviceItems])

    return (

        <section className="big-section wow animate__fadeIn">
            <div className="container">
                <div className="row row-cols-1 row-cols-lg-4 row-cols-sm-2">
                    {
                        serviceItems.slice(0, 4).map((item, i) =>

                                <div className="col md-margin-40px-bottom wow animate__fadeIn"
                                     data-wow-delay={(i * 0.2) + "s"} key={i}>
                                    <div className="feature-box text-center">
                                        <div className="feature-box-icon mb-5">
                                            <img className="line-icon-services"
                                                 src={item.better_featured_image !== null ? item.better_featured_image.source_url : `http://placehold.it/300x200&text=${item.title.rendered}`}
                                                 alt={item.title.rendered}/>
                                        </div>
                                        <div className="feature-box-content last-paragraph-no-margin">
                                        <span className="alt-font font-weight-800 margin-10px-bottom d-block text-dark-purple">
                                            {item.title.rendered}
                                        </span>
                                            <p className="lg-w-100 px-2">
                                                {
                                                    item.excerpt.rendered.replace(/<(?:.|\n)*?>/gm, '')
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Services;