import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link} from "react-router-dom";
import React from "react";

const BlogCardSkeleton = () => {

    function hightCalculation() {
        if (window.innerWidth > 991) {
            return (1320 / 3 - 30) * 2 / 3 + 'px'
        }
        else if (window.innerWidth < 992 && window.innerWidth > 425) {
            return 720 / 2 - 30 + 'px'
        }
        else {
            return window.innerWidth - 30 + 'px'
        }
    }


    return (

        // <div className="bloglist item">
        //     <div className="post-content">
        //         <div className="post-image">
        //             <Skeleton height={hightCalculation()} />
        //         </div>
        //         <div className="post-text">
        //             {/* <span className="p-tagline">Tips &amp; Tricks</span> */}
        //             <span className="p-date">
        //                 <Skeleton width="10%" />
        //             </span>
        //             <h4><Skeleton width="60%" />
        //             </h4>
        //             <p>
        //                 <Skeleton count={3} />
        //             </p>
        //             <p>
        //                 <Skeleton width="60%" />
        //             </p>
        //         </div>
        //     </div>
        // </div>

    <div className="grid-item wow animate__fadeIn" data-wow-delay="0.2s">
        <div className="blog-post margin-20px-bottom">
            <div
                className="blog-post-image margin-15px-bottom md-margin-35px-bottom xs-margin-25px-bottom">
                <div >
                    <Skeleton height={hightCalculation()} />
                </div>
            </div>
            <div
                className="post-details margin-15px-bottom md-margin-10px-bottom xs-no-margin-bottom">
                <div className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray d-block margin-10px-bottom xs-margin-10px-bottom blogtextheight">
                    <Skeleton width="80%" />
                </div>
                <p className="w-95 mb-2">
                    <Skeleton width="40%" />
                </p>
                <span
                    className="separator bg-primary"/>
                <div className="alt-font font-weight-500 text-extra-small text-uppercase text-primary">
                    <Skeleton width="20%" />
                </div>
            </div>
        </div>
    </div>
    );
};

export default BlogCardSkeleton;