import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import IndexPage from "../pages/Home/IndexPage";
import About from "../pages/Common/About";
import Contact from "../pages/Common/Contact";
import Layout from "./layout/Layout";
import BlogList from "../pages/Blog/BlogList";
import NotFound from "../pages/Common/NotFound";
import BlogDetail from "../pages/Blog/BlogDetail";
import ChangeRoute from "./Tools/ChangeRoute";
import BlogListByCategory from "../pages/Blog/BlogListByCategory";
import BlogListByTag from "../pages/Blog/BlogListByTag";
import JoinUs from '../pages/Common/JoinUs'
import ServicesList from "../pages/Services/servicesList";
import EventList from "../pages/Event/EventList";
import EventDetail from "../pages/Event/EventDetail";
import FaqList from "../pages/Faq/FaqList";
import ConsultingList from "../pages/Consulting/ConsultingList";

const Routing = () => {
    return (
        <Router>
            <div className="App">
                <Layout>
                    <ChangeRoute/>
                    <Switch>
                        <Route path="/" exact>
                            <IndexPage/>
                        </Route>
                        <Route path="/services" exact>
                            <ServicesList/>
                        </Route>
                        <Route path="/about" exact>
                            <About/>
                        </Route>
                        <Route path="/faq" exact>
                            <FaqList/>
                        </Route>
                        <Route path="/consulting" exact>
                            <ConsultingList/>
                        </Route>
                        <Route path="/contact" exact>
                            <Contact/>
                        </Route>
                        <Route path="/join-us" exact>
                            <JoinUs/>
                        </Route>
                        <Route path="/blog" exact>
                            <BlogList/>
                        </Route>
                        <Route path="/blog/:slug" exact>
                            <BlogDetail/>
                        </Route>
                        <Route path="/blog/category/:slug" exact>
                            <BlogListByCategory/>
                        </Route>
                        <Route path="/blog/tag/:slug" exact>
                            <BlogListByTag/>
                        </Route>
                        <Route path="/event" exact>
                            <EventList/>
                        </Route>
                        <Route path="/event/:slug" exact>
                            <EventDetail/>
                        </Route>
                        <Route path="*" exact>
                            <NotFound/>
                        </Route>
                    </Switch>
                </Layout>
            </div>
        </Router>

    );
};

export default Routing;