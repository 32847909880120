import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const EventCardSkeleton = () => {
    function hightCalculation() {
        if (window.innerWidth > 991) {
            return (1320 / 3 - 30) * 2 / 3 + 'px'
        } else if (window.innerWidth < 992 && window.innerWidth > 425) {
            return 720 / 2 - 30 + 'px'
        } else {
            return window.innerWidth - 30 + 'px'
        }
    }

    return (

        <div className="grid-item wow animate__fadeIn">
            <div
                className="blog-post text-center bg-white box-shadow box-shadow-large-hover border-bottom border-width-4px border-color-fast-blue">
                <div className="blog-post-image">
                    <div>
                        <Skeleton height={hightCalculation()}/>
                        <div
                            className="blog-rounded-icon bg-white border-color-white absolute-middle-center">
                            <i className="feather icon-feather-arrow-right text-extra-dark-gray icon-extra-small"/>
                        </div>
                    </div>
                </div>
                <div className="post-details padding-40px-all lg-padding-35px-all">
                    <div
                          className="post-author text-medium text-tussock-hover text-uppercase d-block margin-10px-bottom">
                        <Skeleton width="30%"/>
                    </div>
                    <div
                          className="text-extra-dark-gray text-tussock-hover font-weight-500 line-height-26px alt-font d-block">
                        <Skeleton width="30%"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventCardSkeleton;