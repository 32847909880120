import {createContext, useState} from 'react';
export const PartnersDataContext = createContext(undefined);

export const PartnersDataProvider = (props) => {
    const [partnersItems, setPartnersItems] = useState([])
    return (
        <PartnersDataContext.Provider value={{partnersItems, setPartnersItems}}>
            {props.children}
        </PartnersDataContext.Provider>
    );
};