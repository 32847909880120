import {createContext, useState} from 'react';
export const BlogCategoryDataContext = createContext(undefined);

export const BlogCategoryDataProvider = (props) => {
    const [blogCategoryItems, setBlogCategoryItems] = useState([])
    return (
        <BlogCategoryDataContext.Provider value={{blogCategoryItems, setBlogCategoryItems}}>
            {props.children}
        </BlogCategoryDataContext.Provider>
    );
};