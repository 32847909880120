import React, {useContext, useEffect, useState} from 'react';
import {apiGet} from "../../components/Tools/apiConfig";
import {Helmet} from "react-helmet";
import {EventDataContext} from "../../context/EventDataContext";
import EventCard from "../../components/Event/EventCard";
import EventCardSkeleton from "../../components/Event/EventCardSkeleton";
import {Link} from "react-router-dom";

const EventList = () => {
    const {eventItems, setEventItems} = useContext(EventDataContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [itemEnd, setItemEnd] = useState(6);
    const [pageNumber, setPageNumber] = useState(Math.floor(Number(eventItems.length) / itemEnd) + 1);
    const [itemStart, setItemStart] = useState(0);
    const [activePage, setActivePage] = useState(1);

    const goToPage = e => {
        document.getElementById('words').scrollIntoView();
        let gtNumb = parseInt(e.target.attributes['data-gt'].value);
        let iStart = 0;
        let iEnd = 8;
        let newEnd = gtNumb * iEnd;
        let newStart = newEnd - iEnd;
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const prevPage = () => {
        document.getElementById('words').scrollIntoView();
        let gtNumb = (activePage > 1) ? activePage - 1 : activePage;
        let iStart = 0;
        let iEnd = 8;
        let newEnd = gtNumb * iEnd //4
        let newStart = newEnd - iEnd
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const nextPage = () => {
        document.getElementById('words').scrollIntoView();
        let gtNumb = (activePage !== pageNumber) ? activePage + 1 : activePage;
        let iStart = 0;
        let iEnd = 8;
        let newEnd = gtNumb * iEnd //4
        let newStart = newEnd - iEnd
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const loadMore = () => {
        setItemEnd(itemEnd + 4);
    }


    const getEventItems = async () => {

        const baseURL = '/wp-json/wp/v2/event?_fields[]=title&_fields[]=content&_fields[]=featured_media&_fields[]=slug&_fields[]=excerpt&_fields[]=better_featured_image&_fields[]=date&_fields[]=date&_fields[]=date&_fields[]=date&_fields[]=gallery';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setEventItems(response.data);
                setIsLoaded(true);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (eventItems.length > 0) {
            setIsLoaded(true)
        } else {
            getEventItems();
        }
    }, [eventItems])


    return (
        <>
            <Helmet>
                <title>AcceLiot - Events</title>
            </Helmet>
            <section
                className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                            <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                رویدادها
                            </h1>
                            {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                        </div>
                        <div
                            className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                            <ul>
                                <li><Link to="/">صفحه اصلی</Link></li>
                                <li className="text-primary">رویدادها</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            <section className="section-word-list pt-5" aria-label="section">
                <div className="container mt50">
                    <div className="row">
                        {
                            (isLoaded === true) ?
                                eventItems.slice(0, 6).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <EventCard elements={item} index={i}/>
                                    </div>
                                ) :
                                Array.apply(0, Array(6)).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <EventCardSkeleton key={i}/>
                                    </div>
                                )
                        }
                    </div>
                    <div className="spacer-single"/>
                    <ul className="pagination mt-5">
                        {
                            (isLoaded === true) ?
                                (eventItems.length > 6) ?
                                    <button type="button" className="btn btn-dark-gray mx-auto"
                                            onClick={loadMore}>مشاهده
                                        بیشتر
                                    </button> : null : null
                        }

                    </ul>

                </div>
            </section>
        </>
    )
};

export default EventList;