import {createContext, useState} from 'react';
export const FaqDataContext = createContext(undefined);

export const FaqDataProvider = (props) => {
    const [faqItems, setFaqItems] = useState([])
    return (
        <FaqDataContext.Provider value={{faqItems, setFaqItems}}>
            {props.children}
        </FaqDataContext.Provider>
    );
};