import React from 'react';
import {Link} from "react-router-dom";

const EventCard = (props) => {
    let item = props.elements
    let delay = 0.2 * (Number(props.index) + 1) + "s"
    return (
        <div className="grid-item wow animate__fadeIn" data-wow-delay={delay}>
            <div
                className="blog-post text-center bg-white box-shadow box-shadow-large-hover border-bottom border-width-4px border-color-fast-blue">
                <div className="blog-post-image bg-white">
                    <Link to={"/event/" + item.slug}>
                        <img src={(item.featured_media === 0) ? '' : item.better_featured_image.source_url} alt=""/>
                        <div
                            className="blog-rounded-icon bg-white border-color-white absolute-middle-center">
                            <i className="feather icon-feather-arrow-right text-extra-dark-gray icon-extra-small"/>
                        </div>
                    </Link>
                </div>
                <div className="post-details padding-40px-all lg-padding-35px-all">
                    <Link to={"/event/" + item.slug}
                          className="post-author text-medium text-tussock-hover text-uppercase d-block margin-10px-bottom">
                        {
                            new Date(item.date).toLocaleDateString('fa-IR', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })
                        }
                    </Link>
                    <Link to={"/event/" + item.slug}
                          className="text-extra-dark-gray text-tussock-hover font-weight-500 line-height-26px alt-font d-block">
                        {item.title.rendered}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default EventCard;