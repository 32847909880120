import React, {useContext, useEffect, useState} from 'react';
import {BlogDataContext} from "../../context/BlogDataContext";
import {Link} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Keyboard, Autoplay} from "swiper";
import BlogCard from "./BlogCard";
import {apiGet} from "../Tools/apiConfig";
import BlogCardSkeleton from "./BlogCardSkeleton";

const LatestBlog = ({count}) => {
    const {blogItems, setBlogItems} = useContext(BlogDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const getBlogItems = async () => {

        const baseURL = '?rest_route=/wp/v2/posts&_embed&per_page=100';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setBlogItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (blogItems.length > 0) {
            setIsLoaded(true)
        } else {
            getBlogItems();
        }
    }, [blogItems])

    return (
        <div
            className="col-12 col-md-9 col-lg-12 position-relative wow animate__fadeIn"
            data-wow-delay="0.2s">
            <Swiper
                modules={[Autoplay, Navigation, Keyboard]}
                className={'black-move blog-classic'}
                spaceBetween={30}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false
                }}
                breakpoints={{
                    992: {
                        slidesPerView: 3,
                        autoplay: {
                            delay: 9999999
                        }
                    }
                }}
                keyboard={{enabled: true, onlyInViewport: true}}

                navigation={{
                    prevEl: ".swiper-button-previous-nav",
                    nextEl: ".swiper-button-next-nav",
                }}
            >

                <div className="swiper-wrapper">
                    {
                        blogItems.slice(0, count).map((item, i) =>
                            <SwiperSlide key={i}>
                                {
                                    (isLoaded) ?
                                        <BlogCard
                                            elements={item}
                                        />
                                        :
                                        <BlogCardSkeleton/>
                                }
                            </SwiperSlide>
                        )}
                </div>

            </Swiper>
        </div>

    )
};

export default LatestBlog;