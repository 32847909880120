import React from 'react';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard, Pagination} from "swiper";


const About = () => {
    return (
        <>
            <Helmet>
                <title> AcceLiot - About Us</title>
            </Helmet>

            <section
                className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small"
                style={{visibility: "visible", animationName: "fadeIn"}}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                            <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                درباره ما
                            </h1>
                            {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                        </div>
                        <div
                            className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                            <ul>
                                <li><Link to="/">صفحه اصلی</Link></li>
                                <li className="text-primary">درباره ما</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>


            <section className="overflow-visible cover-background wow animate__fadeIn">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-lg-6  col-md-10 wow animate__fadeIn" data-wow-delay="0.4s">
                            <div className="alt-font font-weight-500 margin-30px-bottom d-flex">
                                <span
                                    className="flex-shrink-0 w-30px h-1px bg-primary opacity-7 align-self-center margin-20px-right"/>
                                <div className="flex-grow-1"><span className="text-primary text-uppercase">درباره شتابدهنده اینترنت اشیا</span>
                                </div>
                            </div>
                            <h5 className="alt-font text-uppercase text-extra-dark-gray font-weight-700 w-85 margin-30px-bottom xl-w-100">تاریخچه
                                تاسیس آکادمی اینترنت اشیاء</h5>
                            <p className="w-100 xl-w-100 text-justify"> آکادمی اینترنت اشیاء با هدف فرهنگ‌سازی دانش
                                اینترنت
                                اشیاء و ایجاد بستر تخصصی آموزشی در زمینه اینترنت اشیاء فعالیت خود را از ابتدای سال ۱۳۹۴
                                آغاز
                                کرده است. با توجه به اهمیت فراوان اینترنت اشیاء و فراگیر شدن آن در دنیا، نیاز به بستری
                                تخصصی
                                جهت آموزش و توانمندسازی در حوزه‌ی اینترنت اشیاء احساس می‌شود. آکادمی اینترنت اشیاء با
                                بهره‌گیری از توان متخصصین و نخبگان جوان کشور در راستای ایجاد این بستر تخصصی آموزشی گام
                                برداشت. با بررسی متدهای نوین آموزشی در کشورهای پیشرفته، برای اولین بار کارگاه‌هایی
                                متفاوت و
                                کوتاه مدت را طراحی و اجرا کرد. هدف از این کارگاه‌های کوتاه مدت انتقال تمامی مطالب
                                کاربردی در
                                کمترین زمان ممکن و افزایش توانایی اتکا به خود جهت ادامه‌ی راه و یادگیری بیشتر است.
                                آکادمی
                                اینترنت اشیاء همچنین با رصد تمامی فعالیت‌های شرکت‌های بزرگ در حوزه‌ی اینترنت اشیاء،
                                نیازهای
                                فنی و توانمندی‌های مورد نیاز جهت فعالیت در این حوزه در دنیا و همچنین ایران را بررسی کرد
                                و
                                سرفصل‌هایی در راستای ایجاد و ارتقاء این توانمندی‌های فنی جهت آموزش در کارگاه‌ها طراحی
                                کرد.
                                تمامی تکنولوژی‌ها و ابزارهای مورد استفاده در تمامی شرکت‌های بزرگ بررسی شد و پرکاربردترین
                                و
                                بهترین آن‌ها جهت آموزش در کارگاه انتخاب شدند. اکنون آکادمی اینترنت اشیاء با داشتن
                                تجربه‌ی
                                کار عملی در حوزه‌ی اینترنت اشیاء و تجربه‌ی برگزاری تعداد زیادی کارگاه‌های کوتاه مدت از
                                سال
                                ۱۳۹۴، پیشرو آموزش اینترنت اشیاء در کشور است.</p>

                        </div>
                        <div
                            className="col-12 col-lg-6 col-md-10 position-relative margin-twenty-five-top lg-margin-30px-top md-margin-9-rem-bottom sm-margin-9-rem-bottom wow animate__fadeIn"
                            data-wow-delay="0.2s">
                            <div
                                className="position-absolute left-15px bottom-0px w-75 lg-bottom-minus-50px xs-bottom-minus-25px"
                                data-parallax-layout-ratio="1.1" style={{bottom: "-16.3222px"}}>
                                <img src="/assets/images/images-aboutus.jpg" alt="" data-no-retina=""/>
                            </div>
                            <div className="w-40 overflow-hidden position-relative md-w-70">
                                <img src="/assets/images/home-interior-design-about-img02.jpg" alt=""
                                     data-no-retina=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-penguin-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div
                            className="col-12 col-md-6 text-center margin-6-rem-bottom lg-margin-4-rem-bottom md-margin-3-rem-bottom wow animate__fadeIn">
                            <span
                                className="alt-font font-weight-500 text-primary text-uppercase d-block margin-10px-bottom">اینترنت اشیاء</span>
                            <h5 className="alt-font font-weight-700 text-uppercase text-extra-dark-gray letter-spacing-minus-1px m-0">
                                درباره مرکز شتابدهی تخصصی
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="container-fluid padding-six-lr">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-12 col-lg-12 col-sm-10 blog-content lg-no-padding-lr">
                            <div className="overflow-hidden margin-5px-right xs-no-margin-right">
                                <div className="row g-0 row-cols-1 row-cols-sm-2">
                                    <div className="col cover-background xs-h-250px"
                                         style={{backgroundImage: "url('/assets/images/aboutus/6-Accelerator.jpg')"}}/>
                                    <div className="col">
                                        <div className="padding-4-rem-all lg-padding-3-rem-all">
                                                <span
                                                    className="alt-font text-extra-dark-gray font-weight-500 d-inline-block margin-15px-bottom text-extra-medium">بر گزاری استارت اپ ویکند</span>
                                            <p>از اوایل سال 98 در یک دوره 3 ماهه، خدمات آموزشی مختلف و دوره‌های تخصصی
                                                اینترنت اشیاء توسط آکادمی اینترنت اشیاء و شرکت‌های همکار به شرکت کنندگان
                                                داده می شود. در طول دوره، افراد شرکت‌کننده یک محصول MVP ساخته و در پایان
                                                دوره، جلسات مشاوره تکمیلی با هر یک از تیم‌ها برای ادامه مسیر جهت ورود به
                                                بازار کار برگزار می‌شود و هر تیم بسته به توانایی های خود به یکی از
                                                مسیرهای مرحله بعدی هدایت می شود.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-hidden margin-5px-right xs-no-margin-right">
                                <div className="row g-0 row-cols-1 row-cols-sm-2">

                                    <div className="col">
                                        <div className="padding-4-rem-all lg-padding-3-rem-all">
                                                <span
                                                    className="alt-font text-extra-dark-gray font-weight-500 d-inline-block margin-15px-bottom text-extra-medium">برگزاری مدرسه تابستانی طرح فرداک
(فرهنگ‌سازی دانش اینترنت اشیاء در کشور)
                                                </span>
                                            <p>آکادمی اینترنت اشیاء در راستای فرهنگ سازی دانش اینترنت اشیاء در کشور، از
                                                اوایل سال ۱۳۹۴ دوره های آموزشی طرح فرداک را به صورت رایگان برگزار
                                                می‌کند. این طرح که برای اولین بار در ایران توسط آکادمی اینترنت اشیاء
                                                برگزار شد، براساس متدهای آموزشی روز دنیا طراحی شده و ویژه‌ی دانشجویان
                                                مقطع کارشناسی است که به حوزه‌ی اینترنت اشیاء علاقه‌مند بوده و متقاضی
                                                گذراندن دوره‌ی کارآموزی خود به‌صورت واحد دانشگاهی و یا آزاد هستند.</p>

                                        </div>
                                    </div>
                                    <div className="col cover-background xs-h-250px"
                                         style={{backgroundImage: "url('/assets/images/aboutus/about-2.jpg')"}}></div>
                                </div>
                            </div>
                            <div
                                className="border-all border-width-1px border-color-medium-gray border-radius-4px overflow-hidden margin-5px-right xs-no-margin-right">
                                <div className="row g-0 row-cols-1 row-cols-sm-2">
                                    <div className="col cover-background xs-h-250px"
                                         style={{backgroundImage: "url('/assets/images/aboutus/about-3.jpg')"}}></div>
                                    <div className="col">
                                        <div className="padding-4-rem-all lg-padding-3-rem-all">
                                                <span
                                                    className="alt-font text-extra-dark-gray font-weight-500 d-inline-block margin-15px-bottom text-extra-medium">برگزاری مدرسه تابستانی طرح آتاک
(آموزش‌های توانمندساز اشتغال اینترنت اشیاء در کشور)</span>
                                            <p>آکادمی اینترنت اشیاء پس از برگزاری موفق طرح فرداک (فرهنگ‌سازی دانش
                                                اینترنت اشیاء در کشور) به مدت ۴ سال و برگزاری بیش از ۲۳۰ رویداد اینترنت
                                                اشیاء از سال ۱۳۹۴ تاکنون و با تجربه حضور فعال در مجامع بین‌المللی، گام
                                                بعدی را با ایجاد طرح آتاک (آموزش‌های توانمندساز اشتغال اینترنت اشیاء در
                                                کشور) در راستای ایجاد اشتغال پایدار در حوزه اینترنت اشیاء طراحی و
                                                برنامه‌ریزی نموده است. در این طرح که از نیمه دوم سال ۱۳۹۷ آغاز گردیده،
                                                به گونه ای برنامه ریزی شده است تا طی مدت سه سال، ۱۲۰۰ شغل در حوزه
                                                اینترنت اشیاء در سطح کشور ایجاد گردد. برای این منظور آکادمی اینترنت
                                                اشیاء در حال برگزاری «پکیج‌های آموزشی توانمندساز اشتغال» است که متناسب
                                                با فرصت‌های شغلی موردنیاز در حوزه اینترنت اشیاء در کشور طراحی گردیده
                                                است.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="big-section overlap-height wow animate__fadeIn section-aboutus">
                <div className="container">
                    <div className="row align-items-center overlap-gap-section">
                        <div
                            className="col-12 col-lg-5 col-md-8 order-md-2 order-lg-1 sm-margin-5-rem-bottom wow animate__fadeIn"
                            data-wow-delay="0.1s">
                            <h4 className="alt-font font-weight-600 text-slate-blue letter-spacing-minus-1px margin-30px-bottom sm-margin-20px-bottom">
                                بر گزاری استارت اپ ویکند
                            </h4>
                            <p className="w-80 lg-w-100 text-justify">
                                از اوایل سال 98 در یک دوره 3 ماهه، خدمات آموزشی مختلف و دوره‌های تخصصی اینترنت اشیاء
                                توسط آکادمی اینترنت اشیاء و شرکت‌های همکار به شرکت کنندگان داده می شود. در طول دوره،
                                افراد شرکت‌کننده یک محصول MVP ساخته و در پایان دوره، جلسات مشاوره تکمیلی با هر یک از
                                تیم‌ها برای ادامه مسیر جهت ورود به بازار کار برگزار می‌شود و هر تیم بسته به توانایی های
                                خود به یکی از مسیرهای مرحله بعدی هدایت می شود.
                            </p>

                        </div>
                        <div
                            className="col-12 col-lg-4 order-md-1 order-lg-2 md-margin-5-rem-bottom wow animate__fadeIn"
                            data-wow-delay="0.3s">
                            <img src="/assets/images/aboutus.jpg" alt="" className="border-radius-6px"/>
                        </div>
                        <div className="col-12 col-lg-2 offset-md-1 col-md-3 order-md-3 wow animate__fadeIn"
                             data-wow-delay="0.5s">
                            <div className="row align-items-center justify-content-center">

                                <div className="col-12 text-center text-sm-start">
                                    <div
                                        className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                        <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                        >4000 </h4><span
                                        className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                    </div>
                                    <span
                                        className="alt-font text-medium text-uppercase d-block">افراد <br/>آموزش دیده</span>
                                    <div className="w-100 h-1px bg-medium-gray margin-2-rem-tb xs-margin-3-rem-tb"/>
                                </div>

                                <div className="col-12 text-center text-sm-start">
                                    <div
                                        className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                        <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                        >30</h4><span
                                        className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                    </div>
                                    <span
                                        className="alt-font text-medium text-uppercase d-block">شرکت های <br/>مشاوره داده شده</span>
                                    <div className="w-100 h-1px bg-medium-gray margin-2-rem-tb xs-margin-3-rem-tb"/>
                                </div>

                                <div className="col-12 text-center text-sm-start">
                                    <div
                                        className="d-flex flex-row align-item-start margin-15px-bottom xs-margin-10px-bottom justify-content-center justify-content-sm-start">
                                        <h4 className="vertical-counter d-inline-flex text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-2px mb-0 sm-letter-spacing-minus-1-half"
                                        >300</h4><span
                                        className="text-extra-dark-gray title-extra-small font-weight-500 margin-5px-left">+</span>
                                    </div>
                                    <span
                                        className="alt-font text-medium text-uppercase d-block">وره های آموزشی  <br/>برگزار شده</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
        ;
};

export default About;