import {useState, useContext, useEffect} from 'react';
import BlogCard from "../../components/Blog/BlogCard";
import {Helmet} from "react-helmet";
import BlogCardSkeleton from "../../components/Blog/BlogCardSkeleton";
import {BlogDataContext} from "../../context/BlogDataContext";
import {apiGet} from "../../components/Tools/apiConfig";
import {BlogTagsDataContext} from "../../context/BlogTagsDataContext";
import {useParams} from "react-router-dom";

export default function BlogListByTag() {
    const {blogItems, setBlogItems} = useContext(BlogDataContext);
    const {blogTagsItems, setBlogTagsItems} = useContext(BlogTagsDataContext);
    const [loading, setLoading] = useState(true);
    const [blogTermsItems, setBlogTermsItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [itemEnd, setItemEnd] = useState(6);
    const [pageNumber, setPageNumber] = useState(Math.floor(Number(blogItems.length) / itemEnd) + 1);
    const [itemStart, setItemStart] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const {slug} = useParams()

    const goToPage = e => {
        window.scrollTo(0, 0);
        let gtNumb = parseInt(e.target.attributes['data-gt'].value);
        let iStart = 0;
        let iEnd = 6;
        let newEnd = gtNumb * iEnd;
        let newStart = newEnd - iEnd;
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const prevPage = () => {
        window.scrollTo(0, 0);
        let gtNumb = (activePage > 1) ? activePage - 1 : activePage;
        let iStart = 0;
        let iEnd = 6;
        let newEnd = gtNumb * iEnd //4
        let newStart = newEnd - iEnd
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const nextPage = () => {
        window.scrollTo(0, 0);
        let gtNumb = (activePage !== pageNumber) ? activePage + 1 : activePage;
        let iStart = 0;
        let iEnd = 6;
        let newEnd = gtNumb * iEnd //4
        let newStart = newEnd - iEnd
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const getBlogTagsItems = async () => {
        const baseURL = '/wp-json/wp/v2/tags?_fields[]=name&_fields[]=slug&_fields[]=count&_fields[]=id';
        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setBlogTagsItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }

    const getTagsId = async () => {
        let filterTag = blogTagsItems.filter(item => {
            return decodeURIComponent(item.slug) === slug
        });
        getTagsPosts(filterTag[0].id);
    };

    const getTagsPosts = (e) => {
        let termsItem = blogItems.filter(function (item) {
            return item.tags.find(el => el === e);
        })
        setBlogTermsItems(termsItem);
        setIsLoaded(true)
    }

    const getBlogItems = async () => {
        const baseURL = '?rest_route=/wp/v2/posts&_embed&per_page=100';
        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setBlogItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }

    const loadMore = () => {
        setItemEnd(itemEnd + 4);
    }

    useEffect(() => {
        if (blogItems.length === 0) {
            getBlogItems()
        }
        if (blogTagsItems.length > 0) {
            getTagsId();
        } else {
            getBlogTagsItems();
        }
    }, [blogItems, blogTagsItems])

    return (
        <>
            <Helmet>
                <title>Acceliot - News</title>
            </Helmet>
            <section className="section-word-list pt-5" aria-label="section">
                <div className="container mt50">
                    <div className="row">
                        {
                            (isLoaded === true) ?
                                blogTermsItems.slice(itemStart, itemEnd).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <BlogCard elements={item}/>
                                    </div>
                                ) :
                                Array.apply(0, Array(6)).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <BlogCardSkeleton/>
                                    </div>
                                )
                        }

                    </div>

                    <div className="spacer-single"/>

                    <ul className="pagination mt-5">

                        {
                            (isLoaded === true) ?
                                (blogTermsItems.length > 6) ?
                                    <button type="button" className="btn btn-dark-gray mx-auto"
                                            onClick={loadMore}>مشاهده
                                        بیشتر
                                    </button> : null : null
                        }

                    </ul>
                </div>
            </section>
        </>
    )
}
