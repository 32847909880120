import {useState, useContext, useEffect} from 'react';
import BlogCard from "../../components/Blog/BlogCard";
import {Helmet} from "react-helmet";
import BlogCardSkeleton from "../../components/Blog/BlogCardSkeleton";
import {BlogDataContext} from "../../context/BlogDataContext";
import {apiGet} from "../../components/Tools/apiConfig";
import {Link} from "react-router-dom";

export default function BlogList() {
    const {blogItems, setBlogItems} = useContext(BlogDataContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [itemEnd, setItemEnd] = useState(6);
    const [pageNumber, setPageNumber] = useState(Math.floor(Number(blogItems.length) / itemEnd) + 1);
    const [itemStart, setItemStart] = useState(0);
    const [activePage, setActivePage] = useState(1);

    const goToPage = e => {
        document.getElementById('words').scrollIntoView();
        let gtNumb = parseInt(e.target.attributes['data-gt'].value);
        let iStart = 0;
        let iEnd = 8;
        let newEnd = gtNumb * iEnd;
        let newStart = newEnd - iEnd;
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const prevPage = () => {
        document.getElementById('words').scrollIntoView();
        let gtNumb = (activePage > 1) ? activePage - 1 : activePage;
        let iStart = 0;
        let iEnd = 8;
        let newEnd = gtNumb * iEnd //4
        let newStart = newEnd - iEnd
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const nextPage = () => {
        document.getElementById('words').scrollIntoView();
        let gtNumb = (activePage !== pageNumber) ? activePage + 1 : activePage;
        let iStart = 0;
        let iEnd = 8;
        let newEnd = gtNumb * iEnd //4
        let newStart = newEnd - iEnd
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const loadMore = () => {
        setItemEnd(itemEnd + 4);
    }


    const getBlogItems = async () => {

        const baseURL = '?rest_route=/wp/v2/posts&_embed&per_page=100';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setBlogItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (blogItems.length > 0) {
            setIsLoaded(true)
        } else {
            getBlogItems();
        }
    }, [blogItems])


    return (
        <>
            <Helmet>
                <title>Acceliot - News</title>
            </Helmet>
            <section
                className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                            <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                اخبار
                            </h1>
                            {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                        </div>
                        <div
                            className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                            <ul>
                                <li><Link to="/">صفحه اصلی</Link></li>
                                <li className="text-primary">اخبار</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            <section className="section-word-list pt-5" aria-label="section">
                <div className="container mt50">
                    <div className="row">
                        {
                            (isLoaded === true) ?
                                blogItems.slice(itemStart, itemEnd).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <BlogCard elements={item}/>
                                    </div>
                                ) :
                                Array.apply(0, Array(6)).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <BlogCardSkeleton/>
                                    </div>
                                )
                        }
                    </div>

                    <div className="spacer-single"/>

                    <ul className="pagination mt-5">

                        {
                            (isLoaded === true) ?
                                (blogItems.length > 6) ?
                                    <button type="button" className="btn btn-dark-gray mx-auto"
                                            onClick={loadMore}>مشاهده
                                        بیشتر
                                    </button> : null : null
                        }

                    </ul>

                </div>
            </section>
        </>
    )
}
