import React, {useContext, useEffect, useState} from 'react';
import PartnersLogo from "../Common/PartnersLogo";
import PartnersLogoSkeleton from "../Common/PartnersLogoSkeleton";
import {ServiceDataContext} from "../../context/ServiceDataContext";
import {apiGet} from "../Tools/apiConfig";
import {PartnersDataContext} from "../../context/PartnersDataContext";

const Partners = () => {
    const {partnersItems, setPartnersItems} = useContext(PartnersDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const getPartnersItems = async () => {

        const baseURL = '/wp-json/wp/v2/partner?_fields[]=title&_fields[]=featured_media&_fields[]=excerpt&_fields[]=better_featured_image';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setPartnersItems(response.data);
                setIsLoaded(true);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (partnersItems.length > 0) {
            setIsLoaded(true)
        } else {
            getPartnersItems();
        }
    }, [partnersItems])


    return (
        <section className="wow animate__fadeIn margin-6-rem-bottom p-0"
                 style={{'visibility': 'visible', 'animationName': 'fadeIn'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div
                        className="col-12 col-md-6 text-center margin-3-rem-bottom margin-3-rem-top md-margin-4-rem-bottom wow animate__fadeIn">
                        <h5 className="alt-font font-weight-700 text-slate-blue letter-spacing-minus-1px">
                            همکاران ما
                        </h5>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-4 row-cols-sm-2 client-logo-style-02">
                    {
                        (isLoaded) ?
                            <PartnersLogo elements={partnersItems}/> :
                            <PartnersLogoSkeleton/>
                    }
                </div>
            </div>
        </section>
    );
};

export default Partners;